@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

$main-font: "Roboto";
$title-font: "Futura PT";
$main-color: #FBBA00;
$sec-color: #FF5B5B;
$purple: #E682FF;
$dark-gray: #4A4A4A;
$blue: #009DE0;
$dark-blue: #024CBC;
$radius: 10px;
$transition: .3s;

.loading {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
  }
/* mixins */
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin box2($bg-color, $radius) {
    background: $bg-color;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: $radius;
}

a,
a:hover,
a:focus,
a:active {
    color: inherit;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

p,
label,
span,
a,
form,
fieldset,
legend,
select,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl {
    line-height: normal;
    margin-bottom: 0px;
}

input,
textarea,
.input {
    background: #ffffff;
    box-shadow: unset;
    border: 1px solid #959595;
    border-radius: 4px;
    padding: .75em .85em;
    width: 100%;
    line-height: normal;
    outline: none;
    display: block;
    transition: $transition;

    &:hover,
    &:focus {
        border: 1px solid $main-color;
    }

    &.rounded-10 {
        border-radius: 10px;
    }

    &.w-min {
        width: 120px;

        @media (max-width: 1199px) {
            width: 100px;
        }

        @media (max-width: 575px) {
            width: 80px;
        }
    }
}

input {
    --size: 1.25rem;

    @media (max-width: 575px) {
        --size: 1rem;
    }

    &[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        width: var(--size);
        height: var(--size);
        padding: 0px;
        border-radius: 2px;
        border: 1px solid #A6A6A6;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #fff;
        background-image: url(imgs/mark.svg);
        background-size: 80%;

        &:checked {
            border: 1px solid $blue;
            background-color: $blue;
        }
    }

    &[type="radio"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        width: var(--size);
        height: var(--size);
        padding: 0px;
        border-radius: 50%;
        border: 2px solid #8E8E8E;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 62%;
            height: 62%;
            border-radius: 50%;
            background-color: #8E8E8E;
            z-index: 10;
            transform: translate(-50%, -50%);
        }

        &:checked {
            border: 2px solid $main-color;

            &::after {
                background-color: $main-color;
            }
        }
    }

    &[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
        }

        &:hover,
        &:focus {
            -moz-appearance: number-input;
        }
    }

    &.from {
        padding-left: 2em;
        background: url(imgs/from.svg) no-repeat;
        background-position: .5em 50%;
        background-size: 1.25em;
    }
}

label {
    display: flex;
    align-items: center;

    span {
        flex: 1;
    }
}

.password {
    position: relative;

    input {
        font-size: inherit;
        font-weight: inherit;
        position: relative;
        z-index: 1;
    }

    button {
        position: absolute;
        top: 0px;
        right: 0.5em;
        z-index: 5;
        color: #777;
        height: 100%;
        width: 2em;
        font-size: 1.2em;
    }
}

select {
    border: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    width: fit-content;
    outline: none;
    line-height: normal;
    background: url(imgs/chevron-down.svg) no-repeat 100% center;
    background-size: auto;
    padding-right: 1em;

    &.input {
        background: url(imgs/chevron-down.svg) no-repeat 98% center;
        background-size: auto;
        padding-right: 1em;
    }
}

.form-search {
    position: relative;
    font-size: 1.1em;

    @media (max-width: 767px) {
        font-size: 1.3em;
    }

    input {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: .75em .85em .75em 3rem;
    }

    button {
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 3rem;
        @include flex-center();
        color: $main-color;
        font-size: 1.35em;
    }
}

/* Range Slider */
.rc-slider {
    &-rail {
        background-color: #DADADA;
    }

    &-track {
        background-color: $main-color;
    }

    &-handle {
        border: solid 2px $main-color;

        &:hover {
            border-color: $sec-color;
        }

        &:focus-visible {
            border-color: $sec-color;
            box-shadow: 0 0 0 3px $main-color;
            outline: none;
        }

        &:active {
            border-color: $sec-color;
            box-shadow: 0 0 5px $sec-color;
        }

        &-dragging {
            border-color: $sec-color  !important;
            box-shadow: 0 0 0 5px $main-color  !important;
        }
    }
}

button {
    background: none;
    border: none;
    padding: unset;
    display: block;
    font-family: inherit;
    outline: none;
    color: inherit;
}

.btn {
    @include flex-center;
    padding: 0.75em 2em;
    text-align: center;
    transition: $transition;
    width: fit-content;
    font-size: 1em;
    line-height: normal;
    font-weight: 500;

    &-1 {
        @extend .btn;
        background-color: $main-color;
        color: #fff;
        font-weight: 700;
        border-radius: 10px;
        border: none;
        position: relative;
        overflow: hidden;

        &.color-2 {
            background-color: $sec-color;
        }

        &.color-3 {
            background-color: $purple;
        }

        &>* {
            position: relative;
            z-index: 2;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-image: linear-gradient(60deg, #FFDC77 -5%, transparent);
            opacity: 0;
            transition: $transition;
        }

        @media (max-width: 575px) {
            border-radius: 5px;
        }

        &:hover,
        &:focus,
        &:active {
            color: #fff;

            &::after {
                opacity: 1;
            }

        }
    }

    &-2 {
        @extend .btn;
        background: $sec-color;
        color: #fff;
        font-weight: 700;
        border-radius: 2em;
        text-transform: uppercase;
        transition: $transition;

        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background: $purple;
        }
    }

    &-3 {
        @extend .btn;
        border-radius: 10px;
        border: 1px solid #959595;
        background: transparent;
        color: #959595;

        &:hover,
        &:focus,
        &:active {
            background: #959595;
            color: #fff;
        }

        @media (max-width: 575px) {
            border-radius: 5px;
        }
    }

    &-4 {
        @extend .btn;
        border-radius: 10px;
        border: 1px solid #F2F2F2;
        background: #F2F2F2;
        color: #000;

        @media (max-width: 575px) {
            border-radius: 5px;
        }
    }

    &:disabled {
        filter: grayscale(1);
        opacity: .75;
        pointer-events: none;
    }
}

h1 {
    text-align: left;
    font-size: 2.5em;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 1em;
}

h2,
h1.inner {
    text-align: left;
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 1em;
    text-transform: unset;
}

h3 {
    text-align: left;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 1em;
    text-decoration: underline;
    text-decoration-thickness: from-font;
}

h4 {
    text-align: left;
    font-size: 1.75em;
    font-weight: 400;
    margin-bottom: 1em;
}

h5 {
    text-align: left;
    font-size: 1.35em;
    font-weight: 600;
    margin-bottom: 1em;
}

h6 {
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 0px;
}

@media (max-width: 991px) {
    h1 {
        font-size: 2em;
    }
}

@media (max-width: 575px) {

    h2,
    h1.inner {
        font-size: 1.75em;
    }
}

.fs {
    &-09 {
        font-size: 0.9em;
    }

    &-11 {
        font-size: 1.1em;
    }

    &-12 {
        font-size: 1.2em;
    }

    &-13 {
        font-size: 1.3em;
    }

    &-14 {
        font-size: 1.4em;
    }

    &-15 {
        font-size: 1.5em;
    }

    &-16 {
        font-size: 1.6em;
    }

    &-17 {
        font-size: 1.7em;
    }

    &-18 {
        font-size: 1.8em;
    }

    &-19 {
        font-size: 1.9em;
    }

    &-20 {
        font-size: 2em;
    }

    &-25 {
        font-size: 2.5em;
    }

    &-30 {
        font-size: 3em;
    }

    &-35 {
        font-size: 3.5em;
    }

    &-40 {
        font-size: 4em;
    }
}

.fw {
    &-3 {
        font-weight: 300;
    }

    &-4 {
        font-weight: 400;
    }

    &-5 {
        font-weight: 500;
    }

    &-6 {
        font-weight: 600;
    }

    &-7 {
        font-weight: 700;
    }

    &-8 {
        font-weight: 800;
    }

    &-9 {
        font-weight: 900;
    }
}

.title-font {
    font-family: $title-font;
}

.main-color {
    color: $main-color;
}

.sec-color {
    color: $sec-color;
}

.white {
    color: #fff;
}

.black {
    color: #000;
}

.blue {
    color: $blue;
}

.gray {
    color: #767474;
}

.light-gray {
    color: #A1A1A1;
}

.success-color {
    color: #5BC242;
}

.danger-color {
    color: #F90000;
}

.bb-1 {
    border-bottom: 1px solid;
}

.td-under {
    text-decoration: underline;
    text-decoration-thickness: from-font;
}

.position-unset {
    position: unset;
}

.flex-1 {
    flex: 1;
}

.mb-6 {
    margin-bottom: 6em;

    @media (max-width: 575px) {
        margin-bottom: 4em;
    }
}

.link {
    font-size: 1.1em;
    color: #000;
    transition: $transition;
    @include flex-center();

    &:hover,
    &:focus {
        color: $main-color;
    }

    &:active {
        color: $dark-gray;
    }
}

.list-marked {
    list-style: none;
    padding-left: 0px;

    li {
        &::before {
            content: "✔";
            color: $sec-color;
            margin-right: .5em;
        }
    }
}

.dash-list {
    list-style: none;
    padding-left: 0px;

    li::before {
        content: "―";
        margin-right: .25em;
    }
}

.box {
    background: #FFFFFF;
    box-shadow: 0px 0px 2px #000000;

    &-2 {
        @include box2(#FFF, 20px);
    }
}

.breadcrumbs {
    margin-bottom: 2em;

    ul {
        width: fit-content;
        list-style: none;
        padding-left: 0px;
        @include flex-center();
        color: #676767;
        font-size: 1.1em;
        font-weight: 500;

        li {
            &:not(:first-child) {
                margin-left: .3em;

                &::before {
                    content: '/';
                    margin-right: .3em;
                }
            }
        }
    }
}

.pagination {
    font-size: 1.1em;

    @media (max-width: 575px) {
        font-size: 1em;
    }

    ul {
        list-style: none;
        padding-left: 0px;
        @include flex-center();
        margin: 0 auto;

        li {
            &:not(:first-child) {
                margin-left: .5em;
            }

            a,
            button {
                color: #959595;
                background-color: transparent;
                border: 1px solid #959595;
                font-weight: 600;
                border-radius: 10px;
                min-width: 2.5em;
                height: 2.5em;
                padding: 0.5em 0.75em;
                transition: $transition;

                &.active {
                    color: #fff;
                    background-color: $main-color;
                    border: 1px solid $main-color;
                }

                svg {
                    stroke-width: 3px;
                }
            }
        }
    }
}

/* sliders */
.swiper {
    --swiper-navigation-color: #000;
    --swiper-navigation-size: 16px;

    @media (max-width: 767px) {
        --swiper-navigation-size: 12px;
    }

    &-pagination-bullet {
        width: 10px;
        height: 10px;
        background: #B6B6B6;
        opacity: 1;

        &-active {
            background: $main-color;
        }
    }

    &-button-next,
    &-button-prev {
        background-color: #fff;
        width: calc(var(--swiper-navigation-size) * 3);
        height: calc(var(--swiper-navigation-size) * 3);
        box-shadow: 0px 0px 2px #000000;
        border-radius: 500px;
        transition: $transition;

        &:hover {
            background-color: #EFEFEF;
        }

        &:after {
            @media (max-width: 767px) {
                font-size: calc(var(--swiper-navigation-size)*1.25);
            }
        }
    }

    &-button-prev {
        left: calc(var(--swiper-navigation-size) * -1.5);
        right: auto;

        @media (max-width: 767px) {
            left: 0px;
        }
    }

    &-button-next {
        right: calc(var(--swiper-navigation-size) * -1.5);
        left: auto;

        @media (max-width: 767px) {
            right: 0px;
        }
    }
}


body {
    margin: 0;
    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    font-weight: 400;
    padding-top: 140px;

    @media (max-width: 1199px) {
        font-size: 14px;
    }

    @media (max-width: 991px) {
        padding-bottom: 50px;
    }

    @media (max-width: 767px) {
        font-size: 12px;
        padding-top: 110px;
    }

    @media (max-width: 575px) {
        padding-top: 70px;
        font-size: 10px;
    }
}


header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;

    .top {
        background-color: $main-color;
        height: 70px;

        @media (max-width: 767px) {
            height: 50px;
        }

        @media (max-width: 575px) {
            height: 20px;
        }
    }

    nav {
        background-color: #000;
        background-image: linear-gradient(to bottom, #000000 0%, #333333 100%);
        color: #fff;
        height: 70px;

        @media (max-width: 767px) {
            height: 60px;
        }

        @media (max-width: 575px) {
            height: 50px;
        }

        .container {
            display: flex;
            align-items: stretch;
            justify-content: center;
            position: relative;

            ul {
                flex: 1;
                display: flex;

                @media (max-width: 991px) {
                    display: none;
                }

                li {
                    flex-grow: 1;

                    a {
                        @include flex-center();
                        height: 100%;
                        width: 100%;
                        font-size: 1.375em;
                        font-weight: 900;
                        background-color: #000;
                        position: relative;

                        @media (max-width: 1399px) {
                            font-size: 1.25em;
                        }

                        @media (max-width: 1199px) {
                            font-weight: 700;
                        }

                        span {
                            position: relative;
                            z-index: 2;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            background-image: linear-gradient(to bottom, transparent, #333333);
                            transition: .5s;
                            opacity: 1;
                            z-index: 0;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 100%;
                            height: 100%;
                            background-image: linear-gradient(to top, transparent, #333333);
                            transition: .5s;
                            opacity: 0;
                            z-index: 0;
                        }

                        &:hover,
                        &:focus,
                        &.active {
                            &::before {
                                opacity: 0;
                            }

                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }

                &:first-child {
                    li {
                        border-left: 1px solid #6E6D6B;
                    }
                }

                &:last-child {
                    li {
                        border-right: 1px solid #6E6D6B;
                    }
                }
            }

            .logo {
                height: 150px;
                margin-bottom: -50px;
                margin-top: -30px;

                @media (max-width: 1399px) {
                    height: 135px;
                    margin-bottom: -40px;
                    margin-top: -25px;
                }

                @media (max-width: 1199px) {
                    height: 125px;
                    margin-bottom: -35px;
                    margin-top: -20px;
                }

                @media (max-width: 991px) {
                    height: 100px;
                    margin-bottom: -20px;
                    margin-top: -10px;
                }

                @media (max-width: 767px) {
                    height: 80px;
                    margin-bottom: -15px;
                    margin-top: -5px;
                }

                @media (max-width: 575px) {
                    height: 70px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }

            .menu {
                display: none;
                font-size: 2em;

                @media (max-width: 991px) {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    right: 0.75rem;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

#catalogMenu {
    --width: 270px;
    position: fixed;
    top: 140px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.4);

    @media (max-width: 1399px) {
        --width: 250px;
    }

    @media (max-width: 1199px) {
        --width: 200px;
        font-size: 12px;
    }

    .container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .level-1 {
        background-color: #484848;
        color: #fff;
        width: var(--width);

        h5 {
            height: 80px;
            padding: .75em 1em;
            display: flex;
            align-items: flex-end;
            margin-bottom: 0px;
        }

        a.active {
            background-color: #C7C7C7;
            color: #393939;
            font-weight: 600;

            .icon {
                color: #393939;
            }

            .chevron {
                opacity: 1;
            }
        }
    }

    .level-2 {
        background-color: #C7C7C7;
        color: #6A6868;
        width: var(--width);
        padding-top: 80px;

        a.active {
            background-color: #F0F0F0;
            color: #393939;
            font-weight: 600;

            .chevron {
                opacity: 1;
            }
        }
    }

    .level-3 {
        background-color: #F0F0F0;
        color: #6A6868;
        width: var(--width);
        padding-top: 80px;

        a.active {
            background-color: #FFFFFF;
            color: #393939;
            font-weight: 600;

            .chevron {
                opacity: 1;
            }
        }
    }

    .level-4 {
        background: #FFFFFF;
        flex: 1;
        padding: 80px 1em 2em;

        .brand {
            background: #FFFFFF;
            box-shadow: 0px 0px 2px #000000;
            width: 100%;
            height: 200px;
            @include flex-center();
            flex-direction: column;

            @media (max-width: 1399px) {
                height: 155px;
            }

            img {
                width: 150px;
                height: 100px;
                object-fit: scale-down;
            }

            h6 {
                color: #6A6868;
            }
        }
    }

    ul {
        list-style: none;
        padding-left: 0px;

        li {
            &:not(:last-child) {
                a::after {
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    left: 1em;
                    right: 1em;
                    height: 1px;
                    background: rgba(217, 217, 217, 0.2);
                }
            }

            a {
                display: flex;
                align-items: center;
                position: relative;
                padding: 1em 1em;
                transform: $transition;

                .icon {
                    color: $main-color;
                    transform: $transition;
                }

                .chevron {
                    opacity: 0;
                    transform: $transition;
                }

                span {
                    flex: 1;
                    padding: 0 .25em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

#mobileMenu {
    position: fixed;
    top: 140px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.4);

    @media (max-width: 767px) {
        top: 110px;
    }

    @media (max-width: 575px) {
        top: 70px;
    }

    .menu {
        background-color: #fff;
        padding: 1em 0 0;
        border-radius: 0 0 10px 10px;

        ul {
            li {
                &:not(:first-child) {
                    margin-top: 1.5em;
                }

                a {
                    font-size: 1.3em;
                    font-weight: 500;
                    color: #000;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
}


main {
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 140px - 500px);
    padding-top: 4em;

    @media (max-width: 1199px) {
        min-height: calc(100vh - 140px - 450px);
    }

    @media (max-width: 991px) {
        min-height: calc(100vh - 140px - 50px);
    }

    @media (max-width: 767px) {
        min-height: calc(100vh - 110px - 50px);
        padding-top: 3em;
    }

    @media (max-width: 575px) {
        min-height: calc(100vh - 70px - 50px);
    }
}

.main-slider {
    border-radius: 0px 0px 20px 20px;
    overflow: hidden;
    position: relative;

    @media (max-width: 767px) {
        border-radius: 0px 0px 14px 14px;
    }

    @media (max-width: 575px) {
        border-radius: 0px 0px 8px 8px;
    }

    .swiper-slide {
        position: relative;
        height: 610px;

        @media (max-width: 1399px) {
            height: 585px;
        }

        @media (max-width: 1199px) {
            height: 500px;
        }

        @media (max-width: 991px) {
            height: 460px;
        }

        @media (max-width: 767px) {
            height: 340px;
        }

        @media (max-width: 575px) {
            height: 240px;
        }

        @media (max-width: 399px) {
            height: 220px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;

            @media (max-width: 991px) {
                object-position: 40% 50%;
            }

            @media (max-width: 575px) {
                object-position: 30% 50%;
            }
        }

        .container {
            height: 100%;
            position: relative;
            z-index: 2;
            padding-top: 6em;

            @media (max-width: 1199px) {
                padding-top: 4em;
            }

            @media (max-width: 767px) {
                font-size: 10px;
                padding-top: 3em;
            }

            @media (max-width: 575px) {
                font-size: 8px;
            }

            @media (max-width: 399px) {
                font-size: 7px;
            }
        }

    }
}

.category-card {
    --line:1.8rem;
    height: 100%;
    border-top: 20px solid $main-color;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px #000000;

    @media (max-width: 1399px) {
        font-size: 14px;
    }

    @media (max-width: 1199px) {
        font-size: 13px;
    }

    @media (max-width: 991px) {
        border-top: 15px solid $main-color;
    }

    @media (max-width: 767px) {
        font-size: 12px;
    }

    @media (max-width: 575px) {
        --line:1.3rem;
        border-top: 10px solid $main-color;
        font-size: 9px;
    }

    h3 {
        line-height: var(--line);
        text-decoration: none;
        margin-bottom: 0px;
        a {
            transition: $transition;

            &:hover,
            &:focus {
                color: $main-color;
            }
        }
    }

    ul {
        height: calc(var(--line)*6);
        overflow: hidden;
        margin: 0.75em 1.5em;
        @media (max-width: 575px) {
            margin: 0.5em;
        }
        li {
            // &:not(:last-child) {
            //     margin-bottom: .75em;
            // }

            a {
                display: block;
                color: #4A4A4A;
                font-size: 1.1em;
                transition: $transition;
                line-height: var(--line);
                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
    }

    .more {
        display: block;
        text-decoration: none;
        background-color: #e5e5e5;
        color: #000;
        transition: $transition;
        font-size: 1.2em;
        font-weight: 500;
        width: 100%;
        text-align: center;
        padding: 0.5em;
        &:hover,
        &:focus,
        &:active {
            background-color: $main-color;
            color: #000;
        }
    }
}

.product-card {
    @media (max-width: 1399px) {
        font-size: 14px;
    }

    @media (max-width: 1199px) {
        font-size: 13px;
    }

    @media (max-width: 991px) {
        font-size: 12px;
    }

    @media (max-width: 575px) {
        font-size: 10px;
    }

    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        position: relative;
        z-index: 1;

        @media (max-width: 1399px) {
            height: 220px;
        }

        @media (max-width: 1199px) {
            height: 200px;
        }

        @media (max-width: 575px) {
            height: 40vw;
        }
    }

    .top-left {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
    }

    .btn-fav {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;
    }

    .discount {
        background-color: $sec-color;
        width: fit-content;
        padding: 0.2em 0.75em;
        border-radius: 1em;
        font-size: 1.1em;
        font-weight: 700;
        color: #fff;
    }

    .bonus {
        background-color: $main-color;
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 2;
        width: fit-content;
        padding: 0.2em 0.75em;
        border-radius: 1em;
        font-size: 1.1em;
        font-weight: 700;
        color: #fff;
    }

    h5 {
        font-size: 1.2em;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2em;
        height: 2.4em;
    }
}

.count {
    &-input {
        @include flex-center();
        background-color: #fff;
        border: 2px solid #C4C4C4;
        border-radius: 10px;
        padding: 0.65em 2em;

        @media (max-width: 1199px) {
            padding: 0.65em;
        }

        @media (max-width: 575px) {
            border-radius: 5px;
        }

        button {
            @include flex-center();
            font-size: 1.5em;
            color: #676767;
            transition: $transition;

            &:hover,
            &:focus {
                color: $main-color;
            }

            &:active {
                color: $sec-color;
            }

            ;
        }

        input {
            border: none;
            flex: 1;
            text-align: center;
            padding: 0px;
            font-size: 1.3em;
            font-weight: 900;
            color: #000;
        }
    }
}

.btn-fav {
    @include flex-center();
    font-size: 1.6em;
}

.product-full {
    position: relative;

    @media (max-width: 767px) {
        .count {
            background-color: #F2F2F2;
            position: fixed;
            bottom: 50px;
            left: 0px;
            width: 100%;
            z-index: 100;
            padding: 1em 3em;
            font-size: 1.3em;
        }
    }

    @media (max-width: 575px) {
        .count {
            padding: .5em 1em;
        }
    }

    .btn-fav {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 10;
    }

    .rating {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
            color: $main-color;
            font-size: 1.5em;
            margin-right: .3em;
        }

        span {
            color: #676767;
            font-size: 1.1em;
        }
    }

    .images {
        --height: 90px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        @media (max-width: 1199px) {
            --height: 75px;
        }

        @media (max-width: 991px) {
            margin-bottom: 3em;
        }

        .thumbslides {
            padding: .5em;
            width: calc(var(--height) + 1em);
            height: calc((var(--height) + 20px)*3);

            @media (max-width: 575px) {
                display: none;
            }

            .swiper-slide {
                height: var(--height);

                img {
                    width: 100%;
                    height: var(--height);
                    object-fit: contain;
                    border-radius: 10px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
                    transition: $transition;
                }

                &-thumb-active {
                    img {
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }

        .main {
            width: auto;
            flex: 1;

            img {
                width: 100%;
                height: 500px;
                object-fit: contain;

                @media (max-width: 1399px) {
                    height: 450px;
                }

                @media (max-width: 1199px) {
                    height: 380px;
                }

                @media (max-width: 575px) {
                    height: 85vw;
                }
            }
        }
    }

    .criteria {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        font-weight: 500;
        font-size: 1.1em;

        span {
            padding: 1em;
            background: #FFFFFF;
            box-shadow: 0px 0px 2px #000000;
            border-radius: 4px;

            &:not(:first-child) {
                margin-left: 1em;
            }
        }
    }

    .characteristics {
        @include box2(#FFF, 20px);
        padding: 2em;

        @media (max-width: 767px) {
            box-shadow: unset;
            border-radius: 0px;
            padding: 0px;
        }

        .specification {
            columns: 2;
            column-gap: 80px;
            font-size: 1.1em;
            font-weight: 500;
            margin-bottom: 1em;

            @media (max-width: 1199px) {
                column-gap: 50px;
            }

            @media (max-width: 991px) {
                columns: 1;
            }

            @media (max-width: 575px) {
                margin-bottom: 0px;
            }

            .line {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                border-bottom: 1px dotted #9C9C9C;
                margin-bottom: 1.5em;
                break-inside: avoid;
            }
        }
    }
}

.tabs {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 100px auto;

    @media (max-width: 767px) {
        display: block;
    }

    &::before {
        content: '';
        position: absolute;
        top: 100px;
        left: 0px;
        width: 75%;
        z-index: 1;
        border-bottom: 1px solid #DADADA;

        @media (max-width: 991px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            content: unset;
        }
    }

    &>button {
        position: relative;
        z-index: 2;
        grid-column: span 2;
        grid-row: 1/ 2;
        font-size: 1.5em;
        font-weight: 600;
        border-bottom: 2px solid transparent;
        transition: $transition;
        margin-bottom: -1px;

        @media (max-width: 991px) {
            grid-column: span 3;
        }

        @media (max-width: 767px) {
            width: 100%;
            padding: 1em 0;
            text-align: left;
            border-top: 1px solid #DADADA;
        }

        @media (max-width: 575px) {
            padding: .75em 0;
        }

        &#tab-btn-2 {
            grid-column: span 3;

            @media (max-width: 991px) {
                grid-column: span 4;
            }
        }

        &.active {
            color: $main-color;
            border-bottom: 2px solid $main-color;

            @media (max-width: 767px) {
                border-bottom: 1px solid $main-color;
            }
        }

        &:hover,
        &:focus {
            color: $main-color;
        }
    }

    .content {
        grid-column: 1 / 13;
        grid-row: 2 / 3;
        padding-top: 3em;

        @media (max-width: 767px) {
            padding-top: 2em;
            padding-bottom: 2em;
        }

        @media (max-width: 575px) {
            padding-top: 1em;
            padding-bottom: 1em;
        }
    }
}

.text {
    p {
        font-size: 1.5em;

        @media (max-width: 767px) {
            font-size: 1.2em;
        }
    }
}

.review {
    border-bottom: 1px solid #DADADA;
    padding-bottom: 2em;
    margin-bottom: 2em;

    @media (max-width: 991px) {
        border-top: 1px solid #DADADA;
        border-bottom: unset;
        padding-top: 2em;
        padding-bottom: 0em;
    }

    .text {
        color: #000;
        font-size: 1.5em;

        @media (max-width: 1199px) {
            font-size: 1.25em;
        }
    }
}

.rating-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .val {
        color: #8E8E8E;
        font-size: 1.1em;
        width: 1.25em;
    }

    .progress {
        flex: 1;
        height: 4px;
        background-color: #C6C6C6;

        &-bar {
            background-color: $main-color;
        }
    }

    .percent {
        color: #000;
        font-size: 1.1em;
        width: 2.5em;
        margin-left: .5em;
    }
}

.offer {
    color: #fff;
    @include flex-center();
    font-family: $title-font;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        opacity: 0;
        transition: $transition;
        z-index: 0;
    }

    &:hover::after {
        opacity: 1;
    }

    &-1 {
        background-color: #FFABAB;

        @media (max-width: 991px) {
            align-items: flex-start;
        }

        img {
            width: 400px;
            height: 300px;
            object-fit: contain;
            position: relative;
            z-index: 1;

            @media (max-width: 1399px) {
                width: 350px;
            }

            @media (max-width: 1199px) {
                width: 300px;
                height: 250px;
            }

            @media (max-width: 991px) {
                width: 275px;
                height: 225px;
            }

            @media (max-width: 767px) {
                width: 220px;
                height: 180px;
            }

            @media (max-width: 575px) {
                width: 140px;
                height: 130px;
            }
        }
    }

    &-2 {
        background-color: $main-color;
        padding: 1em 3em;

        @media (max-width: 1199px) {
            padding: 1em 2em;
        }

        @media (max-width: 575px) {
            padding: 1em;
        }

        img {
            width: 300px;
            height: 300px;
            object-fit: contain;
            position: relative;
            z-index: 1;

            @media (max-width: 1399px) {
                width: 250px;
                height: 250px;
            }

            @media (max-width: 991px) {
                width: 200px;
                height: 200px;
            }

            @media (max-width: 767px) {
                width: 180px;
                height: 180px;
            }

            @media (max-width: 575px) {
                width: 115px;
                height: 115px;
            }
        }
    }

    .text {
        flex: 1;
        padding: 2em;
        position: relative;
        z-index: 1;

        @media (max-width: 1399px) {
            font-size: 14px;
        }

        @media (max-width: 1199px) {
            padding: 1em;
            font-size: 12px;
        }

        @media (max-width: 991px) {
            font-size: 10px;
            padding: 2em;
        }

        @media (max-width: 767px) {
            font-size: 8px;
            padding: 1em 2em;
        }

        @media (max-width: 575px) {
            padding: 1em;
            font-size: 6px;

            .fs-35 {
                font-size: 2.5em;
            }
        }
    }

    .expiration {
        width: 240px;
        text-align: center;
        font-weight: 500;
        font-size: 1.5em;
        position: relative;
        z-index: 1;

        @media (max-width: 1399px) {
            font-size: 1.4em;
            width: 200px;
        }

        @media (max-width: 1199px) {
            width: 180px;
        }

        @media (max-width: 991px) {
            width: 140px;
            position: absolute;
            bottom: 1em;
            left: 1em;
            font-size: 1.2em;
        }

        @media (max-width: 767px) {
            width: 120px;
            bottom: 0.5em;
            left: 0em;
            font-size: 1em;
            font-weight: 400;
        }

        @media (max-width: 575px) {
            left: 0.75em;
            font-size: .8em;
            text-align: left;
        }
    }
}

article {
    &.preview {
        figure {
            background: #F2F2F2;
            box-shadow: 0px 0px 2px #000000;
            border-radius: 10px;
            overflow: hidden;
            position: relative;

            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                position: relative;
                z-index: 1;
                filter: brightness(1);
                transition: $transition;

                @media (max-width: 1399px) {
                    height: 260px;
                }

                @media (max-width: 1199px) {
                    height: 235px;
                }

                @media (max-width: 767px) {
                    height: 200px;
                }

                @media (max-width: 575px) {
                    height: 60vw;
                }
            }

            &:hover {
                cursor: pointer;

                img {
                    filter: brightness(.7);
                }
            }

            figcaption {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 250px;
                height: 100%;
                z-index: 2;
                background: radial-gradient(118.67% 118.67% at -4.13% -7.17%, rgba(246, 246, 246, 0.8) 0%, rgba(237, 237, 237, 0.496) 100%);
                backdrop-filter: blur(10px);
                @include flex-center();
                color: #fff;
                padding: 1em;

                @media (max-width: 1399px) {
                    width: 220px;
                }

                @media (max-width: 1199px) {
                    width: 200px;
                }

                @media (max-width: 767px) {
                    width: 165px;
                }

                @media (max-width: 575px) {
                    width: 55vw;
                }

                h3 {
                    text-decoration: unset;

                    @media (max-width: 1399px) {
                        font-size: 1.35em;
                    }

                    @media (max-width: 767px) {
                        font-size: 1.2em;
                    }
                }
            }
        }

        h5 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.full {
        figure {
            background: #F2F2F2;
            box-shadow: 0px 0px 2px #000000;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            margin-bottom: 3em;

            img {
                width: 100%;
                height: 650px;
                object-fit: cover;
                position: relative;
                z-index: 1;
                filter: brightness(1);
                transition: $transition;

                @media (max-width: 1399px) {
                    height: 600px;
                }

                @media (max-width: 1199px) {
                    height: 500px;
                }

                @media (max-width: 991px) {
                    height: 400px;
                }

                @media (max-width: 767px) {
                    height: 335px;
                }

                @media (max-width: 575px) {
                    height: 60vw;
                }
            }

            &:hover {
                cursor: pointer;

                img {
                    filter: brightness(.7);
                }
            }

            figcaption {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 45%;
                height: 100%;
                z-index: 2;
                background: radial-gradient(118.67% 118.67% at -4.13% -7.17%, rgba(246, 246, 246, 0.8) 0%, rgba(237, 237, 237, 0.496) 100%);
                backdrop-filter: blur(10px);
                @include flex-center();
                color: #fff;
                padding: 2em;

                @media (max-width: 767px) {
                    width: 55%;
                }

                @media (max-width: 575px) {
                    width: 50vw;
                    padding: 1em;
                }

                h2 {
                    font-size: 3em;

                    @media (max-width: 1199px) {
                        font-size: 2.5em;
                    }

                    @media (max-width: 991px) {
                        font-size: 2em;
                    }

                    @media (max-width: 575px) {
                        font-size: 1.5em;
                    }
                }
            }
        }

        h3 {
            text-decoration: none;
            font-weight: 600;
            margin-bottom: 1.25em;
            margin-top: 2em;

            @media (max-width: 575px) {
                margin-bottom: 0.75em;
                margin-top: 1.5em;
            }
        }

        p {
            font-size: 1.5em;
            margin-bottom: 1.5em;

            @media (max-width: 991px) {
                font-size: 1.3em;
                margin-bottom: 1.3em;
            }

            @media (max-width: 575px) {
                margin-bottom: 1em;
            }
        }
    }
}

#delivery-map {
    position: relative;

    .btns {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-bottom: 2em;

        @media (min-width: 992px) {
            display: none;
        }

        button {
            color: #959595;
            font-size: 1.4em;
            padding: 1em;
            font-weight: 700;
            border-bottom: 2px solid #DADADA;
            flex-grow: 1;

            &.active {
                color: #000;
                border-bottom: 2px solid $main-color;
            }

            @media (max-width: 575px) {
                padding: .75em 0;
            }
        }
    }

    .params {
        padding-top: 3em;
        padding-bottom: 6em;

        @media (max-width: 991px) {
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    img {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 991px) {
            position: relative;
            height: 500px;
        }

        @media (max-width: 767px) {
            height: 450px;
        }

        @media (max-width: 575px) {
            height: 350px;
        }
    }

    form {
        background: #FFFFFF;
        box-shadow: 0px 0px 2px #000000;
        position: relative;
        z-index: 10;
        padding: 3em;

        @media (max-width: 991px) {
            padding: 0px;
            box-shadow: unset;
        }

        .box {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 2em;
            transition: $transition;

            @media (max-width: 1199px) {
                padding: 1em;
            }

            .btn-1 {
                border-radius: 4px;
            }
        }

        input:checked+.box {
            box-shadow: 0px 0px 2px $main-color;
        }
    }
}


/* Filter (desktop) */
.filter {
    position: sticky;
    top: 140px;
    left: 0px;
    width: 100%;

    fieldset {
        margin-bottom: 2em;

        legend {
            font-size: 1.1em;
            font-weight: 700;
            margin-bottom: 0.75em;
        }

        label {
            margin-bottom: .75em;
        }
    }
}

/* Filter (mobile) */
.filterMobile {
    --top: 60px;
    --bottom: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 575px) {
        --top: 50px;
        --bottom: 50px;
    }

    .main {
        height: calc(100vh - var(--bottom));

        fieldset {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .top {
            @include flex-center();
            border-bottom: 1px solid #DADADA;
            padding: 0 1em;
            height: var(--top);

            legend {
                flex: 1;
                text-align: center;
                font-size: 1.5em;
                font-weight: 700;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0 0.5em;
            }

            button {
                @include flex-center();
                font-size: 1.2em;
            }
        }

        ul {
            height: calc(100vh - var(--top) - var(--bottom));
            list-style: none;
            padding-left: 0px;
            overflow-y: auto;
            font-size: 1.2em;

            li {
                padding: 1em;
                border-bottom: 1px solid #DADADA;

                button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }
            }
        }
    }

    .bottom {
        background-color: #F2F2F2;
        padding: 0 1em;
        height: var(--bottom);
        box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.13);
        @include flex-center();
    }
}


#contacts-page {
    .box {
        min-height: 400px;
        padding: 2em;

        @media (max-width: 1199px) {
            min-height: 350px;
        }

        @media (max-width: 767px) {
            box-shadow: none;
            padding: 0px;
            min-height: fit-content;
            border-bottom: 1px solid #DADADA;
            padding: 2em 0px;
        }
    }

    .shop {
        display: flex;
        flex-direction: row-reverse;
        align-items: stretch;
        justify-content: center;
        padding: 0px;

        @media (max-width: 767px) {
            display: block;
            border-bottom: none;
        }

        .map {
            width: 45%;

            @media (max-width: 767px) {
                width: 100%;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-info {
            flex: 1;
            padding: 2em;

            @media (max-width: 767px) {
                padding: 2em 0px;
            }
        }
    }
}

/* SHOPPING CART */
.cart-item {
    padding: 2em 0;
    border-bottom: 1px solid #DADADA;
    @include flex-center();

    @media (max-width: 767px) {
        align-items: stretch;
    }

    .sale-price {
        font-size: 17px;
        font-weight: normal;
    }

    img {
        width: 160px;
        height: 160px;
        object-fit: contain;
        background-color: #F2F2F2;
        border-radius: 8px;
        padding: 0.75em;

        @media (max-width: 1399px) {
            width: 120px;
            height: 120px;
        }

        @media (max-width: 1199px) {
            width: 100px;
            height: 100px;
        }

        @media (max-width: 575px) {
            width: 90px;
            height: 90px;
        }
    }

    .info {
        margin: 0 2em;
        flex: 1;
        display: flex;
        align-items: center;

        @media (max-width: 1399px) {
            margin: 0 1em;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .count-input {
            padding: 0.65em;
            width: 150px;

            @media (max-width: 1199px) {
                width: 120px;
            }
        }

        .price {
            font-size: 1.5em;
            font-weight: 700;
            width: 120px;
            text-align: right;

            @media (max-width: 1399px) {
                width: 100px;
            }

            @media (max-width: 767px) {
                width: 100%;
                text-align: left;
                margin: 0.5em 0;
            }
        }
    }

    .btns {
        display: flex;
        flex-direction: column;

        @media (max-width: 767px) {
            justify-content: space-between;
        }
    }
}

.total {
    @include box2(#F2F2F2, 20px);
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: 991px) {
        position: fixed;
        z-index: 100;
        bottom: 50px;
        left: 0px;
        width: 100%;
        border-radius: 0px;
        box-shadow: unset;
        border-top: 1px solid #DADADA;
        padding: 1.5em 20%;

        &-padding {
            padding-bottom: 120px;
        }
    }

    @media (max-width: 767px) {
        &-padding {
            padding-bottom: 100px;
        }
    }

    @media (max-width: 575px) {
        padding: 1em;

        &-padding {
            padding-bottom: 80px;
        }
    }

    hr {
        height: 2px !important;
        background-color: #DADADA;
        margin: 1em 0 .5em;
        opacity: 1;

        @media (max-width: 991px) {
            margin: .5em 0;
        }
    }

    table {
        width: 100%;
        font-size: 1.1em;

        tr {
            td {
                padding: .5em 0;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

aside {
    @include box2(#FFFFFF, 20px);
    width: 100%;
    height: 230px;
}

#checkout {
    hr {
        height: 1px !important;
        background-color: #DADADA;
        margin: 1em 0;
        opacity: 1;
    }
}


#enter-page {
    padding: 6em 0;

    @media (max-width: 1199px) {
        padding: 5em 0;
    }

    @media (max-width: 991px) {
        padding: 4em 0;
    }

    @media (max-width: 767px) {
        padding: 3em 0;
    }

    @media (max-width: 575px) {
        padding: 2em 0;
    }
}


/* Account */
.account {
    &-nav {
        padding: 1.25em 0px;

        @media (max-width: 991px) {
            padding: 0px;
        }

        ul {
            list-style: none;
            padding-left: 0px;

            li {

                a,
                button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding: .75em 1.5em;
                    position: relative;
                    overflow: hidden;
                    font-size: 1.35em;
                    font-weight: 500;
                    text-align: left;

                    @media (max-width: 991px) {
                        font-size: 1.5em;
                        font-weight: 700;
                    }

                    @media (max-width: 575px) {
                        padding: 1em 0px;
                    }

                    &.active {
                        color: $main-color;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 10%;
                            left: -2.5px;
                            height: 80%;
                            width: 5px;
                            background-color: $main-color;
                            border-radius: 2.5px;
                        }
                    }

                    svg {
                        @media (min-width: 992px) {
                            display: none;
                        }
                    }
                }

                button {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 1.5em;
                        right: 1.5em;
                        height: 1px;
                        background-color: #DADADA;

                        @media (max-width: 575px) {
                            left: 0px;
                            right: 0px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        .box-2 {
            box-shadow: none;
            border-radius: unset;
        }
    }
}

.order {
    margin-bottom: 3em;

    hr {
        background-color: #DADADA;
        height: 1px;
        width: 100%;
        margin: 1em 0px;
        opacity: 1;
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3em;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-page {
        @media (max-width: 991px) {
            padding-bottom: 6em;
        }
    }

    &-item {
        border-top: 1px solid #DADADA;
        padding: 2em 0;
        display: flex;

        img {
            width: 200px;
            height: 200px;
            object-fit: contain;
            background: #F2F2F2;
            border-radius: 10px;
            padding: 1em;
            margin-right: 2em;

            @media (max-width: 1399px) {
                width: 150px;
                height: 150px;
                margin-right: 1em;
            }

            @media (max-width: 1199px) {
                width: 100px;
                height: 100px;
            }

            @media (max-width: 575px) {
                width: 90px;
                height: 90px;
            }
        }

        .info {
            flex: 1;
            font-size: 1.1em;

            @media (max-width: 1399px) {
                font-size: 1em;
            }

            @media (max-width: 767px) {
                font-size: 1.2em;
            }
        }
    }
}

.address {
    box-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
    border-radius: 20px;
    padding: 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;

    @media (max-width: 767px) {
        button.btn {
            color: #fff;
            padding: 0.4em;
        }
    }
}

.bonus {
    &-page {
        .box-3 {
            @include box2(#fff, 20px);
            padding: 2em;

            @media (max-width: 767px) {
                padding: 1.5em;
            }
        }
    }

    &-card {
        @include box2($main-color, 20px);
        padding: 2em;
        height: 250px;
        position: relative;

        @media (max-width: 1399px) {
            height: 225px;
        }

        @media (max-width: 1199px) {
            height: 200px;
        }

        @media (max-width: 767px) {
            height: 180px;
        }

        &>* {
            z-index: 1;
        }

        .logo {
            position: absolute;
            bottom: 1.5em;
            right: 2em;
            height: 55px;
            width: 150px;
            object-fit: contain;
            z-index: 10;

            @media (max-width: 1399px) {
                bottom: 1em;
                height: 40px;
                width: 100px;
            }
        }
    }

    &-operation {
        padding-bottom: 1.5em;
        border-bottom: 2px solid #DADADA;
        margin-bottom: 1.5em;

        @media (max-width: 767px) {
            border-bottom: 1px solid #DADADA;
        }
    }

    &-tabs {
        display: flex;
        align-items: center;

        button {
            font-size: 1.3em;
            color: #959595;
            font-weight: 500;
            border-right: 2px solid #DADADA;
            padding-right: 2em;

            &:not(:first-child) {
                margin-left: 2em;

                @media (max-width: 575px) {
                    margin-left: 1em;
                }
            }

            &.active {
                color: $main-color;
            }

            @media (max-width: 575px) {
                padding-right: 1em;
            }
        }
    }
}


footer {
    height: 500px;
    background-color: #0F0F0F;
    color: #fff;
    padding: 3.5em 0;

    @media (max-width: 1199px) {
        height: 450px;
    }

    @media (max-width: 991px) {
        height: 50px;
        @include box2(#FFFFFF, 0px);
        padding: 0;
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 100;
    }

    ul {
        font-size: 1.1em;

        li {
            &:not(:last-child) {
                margin-bottom: 1em;
            }

            a {
                text-decoration: underline;
                text-decoration-thickness: from-font;
                transition: $transition;

                &:hover,
                &:focus,
                &:active {
                    color: $main-color;
                    text-decoration: underline;
                    text-decoration-thickness: from-font;
                }
            }
        }
    }

    hr {
        background-color: #fff;
        height: 1px !important;
        border: none;
        opacity: 1;
    }

    .social {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a:not(:first-child) {
            margin-left: 1.25em;
        }

        img {
            width: 3em;
            height: 3em;
            object-fit: contain;
        }
    }

    .mobile {
        display: none;
        height: 100%;

        @media (max-width: 991px) {
            display: block;
        }

        nav {
            height: 100%;

            ul {
                height: 100%;
                list-style: none;
                padding-left: 0px;
                display: flex;
                justify-content: space-around;
                align-items: center;

                li {
                    margin-bottom: 0px;

                    a {
                        .stroke {
                            path {
                                stroke: #000000;
                            }
                        }

                        .fill {
                            path {
                                fill: #000000;
                            }
                        }

                        &.active {
                            .stroke {
                                path {
                                    stroke: $main-color;
                                }
                            }

                            .fill,
                            .fill-active {
                                path {
                                    fill: $main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.offcanvas {
    &-start {
        width: 500px;
    }

    &-body {
        padding: 0px;
        position: relative;
    }
}