@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

/* mixins */
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

p,
label,
span,
a,
form,
fieldset,
legend,
select,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl {
  line-height: normal;
  margin-bottom: 0px;
}

input,
textarea,
.input {
  background: #ffffff;
  box-shadow: unset;
  border: 1px solid #959595;
  border-radius: 4px;
  padding: 0.75em 0.85em;
  width: 100%;
  line-height: normal;
  outline: none;
  display: block;
  transition: 0.3s;
}
input:hover, input:focus,
textarea:hover,
textarea:focus,
.input:hover,
.input:focus {
  border: 1px solid #FBBA00;
}
input.rounded-10,
textarea.rounded-10,
.input.rounded-10 {
  border-radius: 10px;
}
input.w-min,
textarea.w-min,
.input.w-min {
  width: 120px;
}
@media (max-width: 1199px) {
  input.w-min,
textarea.w-min,
.input.w-min {
    width: 100px;
  }
}
@media (max-width: 575px) {
  input.w-min,
textarea.w-min,
.input.w-min {
    width: 80px;
  }
}

input {
  --size: 1.25rem;
}
@media (max-width: 575px) {
  input {
    --size: 1rem;
  }
}
input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: var(--size);
  height: var(--size);
  padding: 0px;
  border-radius: 2px;
  border: 1px solid #A6A6A6;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: url(imgs/mark.svg);
  background-size: 80%;
}
input[type=checkbox]:checked {
  border: 1px solid #009DE0;
  background-color: #009DE0;
}
input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: var(--size);
  height: var(--size);
  padding: 0px;
  border-radius: 50%;
  border: 2px solid #8E8E8E;
  position: relative;
}
input[type=radio]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 62%;
  height: 62%;
  border-radius: 50%;
  background-color: #8E8E8E;
  z-index: 10;
  transform: translate(-50%, -50%);
}
input[type=radio]:checked {
  border: 2px solid #FBBA00;
}
input[type=radio]:checked::after {
  background-color: #FBBA00;
}
input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type=number]:hover, input[type=number]:focus {
  -moz-appearance: number-input;
}
input.from {
  padding-left: 2em;
  background: url(imgs/from.svg) no-repeat;
  background-position: 0.5em 50%;
  background-size: 1.25em;
}

label {
  display: flex;
  align-items: center;
}
label span {
  flex: 1;
}

.password {
  position: relative;
}
.password input {
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  z-index: 1;
}
.password button {
  position: absolute;
  top: 0px;
  right: 0.5em;
  z-index: 5;
  color: #777;
  height: 100%;
  width: 2em;
  font-size: 1.2em;
}

select {
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  outline: none;
  line-height: normal;
  background: url(imgs/chevron-down.svg) no-repeat 100% center;
  background-size: auto;
  padding-right: 1em;
}
select.input {
  background: url(imgs/chevron-down.svg) no-repeat 98% center;
  background-size: auto;
  padding-right: 1em;
}

.form-search {
  position: relative;
  font-size: 1.1em;
}
@media (max-width: 767px) {
  .form-search {
    font-size: 1.3em;
  }
}
.form-search input {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0.75em 0.85em 0.75em 3rem;
}
.form-search button {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FBBA00;
  font-size: 1.35em;
}

/* Range Slider */
.rc-slider-rail {
  background-color: #DADADA;
}
.rc-slider-track {
  background-color: #FBBA00;
}
.rc-slider-handle {
  border: solid 2px #FBBA00;
}
.rc-slider-handle:hover {
  border-color: #FF5B5B;
}
.rc-slider-handle:focus-visible {
  border-color: #FF5B5B;
  box-shadow: 0 0 0 3px #FBBA00;
  outline: none;
}
.rc-slider-handle:active {
  border-color: #FF5B5B;
  box-shadow: 0 0 5px #FF5B5B;
}
.rc-slider-handle-dragging {
  border-color: #FF5B5B !important;
  box-shadow: 0 0 0 5px #FBBA00 !important;
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}

.btn, .btn-4, .btn-3, .btn-2, .btn-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75em 2em;
  text-align: center;
  transition: 0.3s;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1em;
  line-height: normal;
  font-weight: 500;
}
.btn-1 {
  background-color: #FBBA00;
  color: #fff;
  font-weight: 700;
  border-radius: 10px;
  border: none;
  position: relative;
  overflow: hidden;
}
.btn-1.color-2 {
  background-color: #FF5B5B;
}
.btn-1.color-3 {
  background-color: #E682FF;
}
.btn-1 > * {
  position: relative;
  z-index: 2;
}
.btn-1::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: linear-gradient(60deg, #FFDC77 -5%, transparent);
  opacity: 0;
  transition: 0.3s;
}
@media (max-width: 575px) {
  .btn-1 {
    border-radius: 5px;
  }
}
.btn-1:hover, .btn-1:focus, .btn-1:active {
  color: #fff;
}
.btn-1:hover::after, .btn-1:focus::after, .btn-1:active::after {
  opacity: 1;
}
.btn-2 {
  background: #FF5B5B;
  color: #fff;
  font-weight: 700;
  border-radius: 2em;
  text-transform: uppercase;
  transition: 0.3s;
}
.btn-2:hover, .btn-2:focus, .btn-2:active {
  color: #fff;
  background: #E682FF;
}
.btn-3 {
  border-radius: 10px;
  border: 1px solid #959595;
  background: transparent;
  color: #959595;
}
.btn-3:hover, .btn-3:focus, .btn-3:active {
  background: #959595;
  color: #fff;
}
@media (max-width: 575px) {
  .btn-3 {
    border-radius: 5px;
  }
}
.btn-4 {
  border-radius: 10px;
  border: 1px solid #F2F2F2;
  background: #F2F2F2;
  color: #000;
}
@media (max-width: 575px) {
  .btn-4 {
    border-radius: 5px;
  }
}
.btn:disabled, .btn-1:disabled, .btn-2:disabled, .btn-3:disabled, .btn-4:disabled {
  filter: grayscale(1);
  opacity: 0.75;
  pointer-events: none;
}

h1 {
  text-align: left;
  font-size: 2.5em;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 1em;
}

h2,
h1.inner {
  text-align: left;
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 1em;
  text-transform: unset;
}

h3 {
  text-align: left;
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 1em;
  text-decoration: underline;
  text-decoration-thickness: from-font;
}

h4 {
  text-align: left;
  font-size: 1.75em;
  font-weight: 400;
  margin-bottom: 1em;
}

h5 {
  text-align: left;
  font-size: 1.35em;
  font-weight: 600;
  margin-bottom: 1em;
}

h6 {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0px;
}

@media (max-width: 991px) {
  h1 {
    font-size: 2em;
  }
}
@media (max-width: 575px) {
  h2,
h1.inner {
    font-size: 1.75em;
  }
}
.fs-09 {
  font-size: 0.9em;
}
.fs-11 {
  font-size: 1.1em;
}
.fs-12 {
  font-size: 1.2em;
}
.fs-13 {
  font-size: 1.3em;
}
.fs-14 {
  font-size: 1.4em;
}
.fs-15 {
  font-size: 1.5em;
}
.fs-16 {
  font-size: 1.6em;
}
.fs-17 {
  font-size: 1.7em;
}
.fs-18 {
  font-size: 1.8em;
}
.fs-19 {
  font-size: 1.9em;
}
.fs-20 {
  font-size: 2em;
}
.fs-25 {
  font-size: 2.5em;
}
.fs-30 {
  font-size: 3em;
}
.fs-35 {
  font-size: 3.5em;
}
.fs-40 {
  font-size: 4em;
}

.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}

.title-font {
  font-family: "Futura PT";
}

.main-color {
  color: #FBBA00;
}

.sec-color {
  color: #FF5B5B;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.blue {
  color: #009DE0;
}

.gray {
  color: #767474;
}

.light-gray {
  color: #A1A1A1;
}

.success-color {
  color: #5BC242;
}

.danger-color {
  color: #F90000;
}

.bb-1 {
  border-bottom: 1px solid;
}

.td-under {
  text-decoration: underline;
  text-decoration-thickness: from-font;
}

.position-unset {
  position: unset;
}

.flex-1 {
  flex: 1;
}

.mb-6 {
  margin-bottom: 6em;
}
@media (max-width: 575px) {
  .mb-6 {
    margin-bottom: 4em;
  }
}

.link {
  font-size: 1.1em;
  color: #000;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.link:hover, .link:focus {
  color: #FBBA00;
}
.link:active {
  color: #4A4A4A;
}

.list-marked {
  list-style: none;
  padding-left: 0px;
}
.list-marked li::before {
  content: "✔";
  color: #FF5B5B;
  margin-right: 0.5em;
}

.dash-list {
  list-style: none;
  padding-left: 0px;
}
.dash-list li::before {
  content: "―";
  margin-right: 0.25em;
}

.box {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px #000000;
}
.box-2 {
  background: #FFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.breadcrumbs {
  margin-bottom: 2em;
}
.breadcrumbs ul {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  list-style: none;
  padding-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #676767;
  font-size: 1.1em;
  font-weight: 500;
}
.breadcrumbs ul li:not(:first-child) {
  margin-left: 0.3em;
}
.breadcrumbs ul li:not(:first-child)::before {
  content: "/";
  margin-right: 0.3em;
}

.pagination {
  font-size: 1.1em;
}
@media (max-width: 575px) {
  .pagination {
    font-size: 1em;
  }
}
.pagination ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.pagination ul li:not(:first-child) {
  margin-left: 0.5em;
}
.pagination ul li a,
.pagination ul li button {
  color: #959595;
  background-color: transparent;
  border: 1px solid #959595;
  font-weight: 600;
  border-radius: 10px;
  min-width: 2.5em;
  height: 2.5em;
  padding: 0.5em 0.75em;
  transition: 0.3s;
}
.pagination ul li a.active,
.pagination ul li button.active {
  color: #fff;
  background-color: #FBBA00;
  border: 1px solid #FBBA00;
}
.pagination ul li a svg,
.pagination ul li button svg {
  stroke-width: 3px;
}

/* sliders */
.swiper {
  --swiper-navigation-color: #000;
  --swiper-navigation-size: 16px;
}
@media (max-width: 767px) {
  .swiper {
    --swiper-navigation-size: 12px;
  }
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #B6B6B6;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #FBBA00;
}
.swiper-button-next, .swiper-button-prev {
  background-color: #fff;
  width: calc(var(--swiper-navigation-size) * 3);
  height: calc(var(--swiper-navigation-size) * 3);
  box-shadow: 0px 0px 2px #000000;
  border-radius: 500px;
  transition: 0.3s;
}
.swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: #EFEFEF;
}
@media (max-width: 767px) {
  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: calc(var(--swiper-navigation-size) * 1.25);
  }
}
.swiper-button-prev {
  left: calc(var(--swiper-navigation-size) * -1.5);
  right: auto;
}
@media (max-width: 767px) {
  .swiper-button-prev {
    left: 0px;
  }
}
.swiper-button-next {
  right: calc(var(--swiper-navigation-size) * -1.5);
  left: auto;
}
@media (max-width: 767px) {
  .swiper-button-next {
    right: 0px;
  }
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  padding-top: 140px;
}
@media (max-width: 1199px) {
  body {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  body {
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 12px;
    padding-top: 110px;
  }
}
@media (max-width: 575px) {
  body {
    padding-top: 70px;
    font-size: 10px;
  }
}

header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
}
header .top {
  background-color: #FBBA00;
  height: 70px;
}
@media (max-width: 767px) {
  header .top {
    height: 50px;
  }
}
@media (max-width: 575px) {
  header .top {
    height: 20px;
  }
}
header nav {
  background-color: #000;
  background-image: linear-gradient(to bottom, #000000 0%, #333333 100%);
  color: #fff;
  height: 70px;
}
@media (max-width: 767px) {
  header nav {
    height: 60px;
  }
}
@media (max-width: 575px) {
  header nav {
    height: 50px;
  }
}
header nav .container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
}
header nav .container ul {
  flex: 1;
  display: flex;
}
@media (max-width: 991px) {
  header nav .container ul {
    display: none;
  }
}
header nav .container ul li {
  flex-grow: 1;
}
header nav .container ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.375em;
  font-weight: 900;
  background-color: #000;
  position: relative;
}
@media (max-width: 1399px) {
  header nav .container ul li a {
    font-size: 1.25em;
  }
}
@media (max-width: 1199px) {
  header nav .container ul li a {
    font-weight: 700;
  }
}
header nav .container ul li a span {
  position: relative;
  z-index: 2;
}
header nav .container ul li a::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent, #333333);
  transition: 0.5s;
  opacity: 1;
  z-index: 0;
}
header nav .container ul li a::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, transparent, #333333);
  transition: 0.5s;
  opacity: 0;
  z-index: 0;
}
header nav .container ul li a:hover::before, header nav .container ul li a:focus::before, header nav .container ul li a.active::before {
  opacity: 0;
}
header nav .container ul li a:hover::after, header nav .container ul li a:focus::after, header nav .container ul li a.active::after {
  opacity: 1;
}
header nav .container ul:first-child li {
  border-left: 1px solid #6E6D6B;
}
header nav .container ul:last-child li {
  border-right: 1px solid #6E6D6B;
}
header nav .container .logo {
  height: 150px;
  margin-bottom: -50px;
  margin-top: -30px;
}
@media (max-width: 1399px) {
  header nav .container .logo {
    height: 135px;
    margin-bottom: -40px;
    margin-top: -25px;
  }
}
@media (max-width: 1199px) {
  header nav .container .logo {
    height: 125px;
    margin-bottom: -35px;
    margin-top: -20px;
  }
}
@media (max-width: 991px) {
  header nav .container .logo {
    height: 100px;
    margin-bottom: -20px;
    margin-top: -10px;
  }
}
@media (max-width: 767px) {
  header nav .container .logo {
    height: 80px;
    margin-bottom: -15px;
    margin-top: -5px;
  }
}
@media (max-width: 575px) {
  header nav .container .logo {
    height: 70px;
  }
}
header nav .container .logo img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
header nav .container .menu {
  display: none;
  font-size: 2em;
}
@media (max-width: 991px) {
  header nav .container .menu {
    display: flex;
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translateY(-50%);
  }
}

#catalogMenu {
  --width: 270px;
  position: fixed;
  top: 140px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
}
@media (max-width: 1399px) {
  #catalogMenu {
    --width: 250px;
  }
}
@media (max-width: 1199px) {
  #catalogMenu {
    --width: 200px;
    font-size: 12px;
  }
}
#catalogMenu .container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
#catalogMenu .level-1 {
  background-color: #484848;
  color: #fff;
  width: var(--width);
}
#catalogMenu .level-1 h5 {
  height: 80px;
  padding: 0.75em 1em;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
}
#catalogMenu .level-1 a.active {
  background-color: #C7C7C7;
  color: #393939;
  font-weight: 600;
}
#catalogMenu .level-1 a.active .icon {
  color: #393939;
}
#catalogMenu .level-1 a.active .chevron {
  opacity: 1;
}
#catalogMenu .level-2 {
  background-color: #C7C7C7;
  color: #6A6868;
  width: var(--width);
  padding-top: 80px;
}
#catalogMenu .level-2 a.active {
  background-color: #F0F0F0;
  color: #393939;
  font-weight: 600;
}
#catalogMenu .level-2 a.active .chevron {
  opacity: 1;
}
#catalogMenu .level-3 {
  background-color: #F0F0F0;
  color: #6A6868;
  width: var(--width);
  padding-top: 80px;
}
#catalogMenu .level-3 a.active {
  background-color: #FFFFFF;
  color: #393939;
  font-weight: 600;
}
#catalogMenu .level-3 a.active .chevron {
  opacity: 1;
}
#catalogMenu .level-4 {
  background: #FFFFFF;
  flex: 1;
  padding: 80px 1em 2em;
}
#catalogMenu .level-4 .brand {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px #000000;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 1399px) {
  #catalogMenu .level-4 .brand {
    height: 155px;
  }
}
#catalogMenu .level-4 .brand img {
  width: 150px;
  height: 100px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}
#catalogMenu .level-4 .brand h6 {
  color: #6A6868;
}
#catalogMenu ul {
  list-style: none;
  padding-left: 0px;
}
#catalogMenu ul li:not(:last-child) a::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 1em;
  right: 1em;
  height: 1px;
  background: rgba(217, 217, 217, 0.2);
}
#catalogMenu ul li a {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1em 1em;
  transform: 0.3s;
}
#catalogMenu ul li a .icon {
  color: #FBBA00;
  transform: 0.3s;
}
#catalogMenu ul li a .chevron {
  opacity: 0;
  transform: 0.3s;
}
#catalogMenu ul li a span {
  flex: 1;
  padding: 0 0.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#mobileMenu {
  position: fixed;
  top: 140px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.4);
}
@media (max-width: 767px) {
  #mobileMenu {
    top: 110px;
  }
}
@media (max-width: 575px) {
  #mobileMenu {
    top: 70px;
  }
}
#mobileMenu .menu {
  background-color: #fff;
  padding: 1em 0 0;
  border-radius: 0 0 10px 10px;
}
#mobileMenu .menu ul li:not(:first-child) {
  margin-top: 1.5em;
}
#mobileMenu .menu ul li a {
  font-size: 1.3em;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

main {
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 140px - 500px);
  padding-top: 4em;
}
@media (max-width: 1199px) {
  main {
    min-height: calc(100vh - 140px - 450px);
  }
}
@media (max-width: 991px) {
  main {
    min-height: calc(100vh - 140px - 50px);
  }
}
@media (max-width: 767px) {
  main {
    min-height: calc(100vh - 110px - 50px);
    padding-top: 3em;
  }
}
@media (max-width: 575px) {
  main {
    min-height: calc(100vh - 70px - 50px);
  }
}

.main-slider {
  border-radius: 0px 0px 20px 20px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 767px) {
  .main-slider {
    border-radius: 0px 0px 14px 14px;
  }
}
@media (max-width: 575px) {
  .main-slider {
    border-radius: 0px 0px 8px 8px;
  }
}
.main-slider .swiper-slide {
  position: relative;
  height: 610px;
}
@media (max-width: 1399px) {
  .main-slider .swiper-slide {
    height: 585px;
  }
}
@media (max-width: 1199px) {
  .main-slider .swiper-slide {
    height: 500px;
  }
}
@media (max-width: 991px) {
  .main-slider .swiper-slide {
    height: 460px;
  }
}
@media (max-width: 767px) {
  .main-slider .swiper-slide {
    height: 340px;
  }
}
@media (max-width: 575px) {
  .main-slider .swiper-slide {
    height: 240px;
  }
}
@media (max-width: 399px) {
  .main-slider .swiper-slide {
    height: 220px;
  }
}
.main-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
@media (max-width: 991px) {
  .main-slider .swiper-slide img {
    -o-object-position: 40% 50%;
       object-position: 40% 50%;
  }
}
@media (max-width: 575px) {
  .main-slider .swiper-slide img {
    -o-object-position: 30% 50%;
       object-position: 30% 50%;
  }
}
.main-slider .swiper-slide .container {
  height: 100%;
  position: relative;
  z-index: 2;
  padding-top: 6em;
}
@media (max-width: 1199px) {
  .main-slider .swiper-slide .container {
    padding-top: 4em;
  }
}
@media (max-width: 767px) {
  .main-slider .swiper-slide .container {
    font-size: 10px;
    padding-top: 3em;
  }
}
@media (max-width: 575px) {
  .main-slider .swiper-slide .container {
    font-size: 8px;
  }
}
@media (max-width: 399px) {
  .main-slider .swiper-slide .container {
    font-size: 7px;
  }
}

.category-card {
  --line:1.8rem;
  height: 100%;
  border-top: 20px solid #FBBA00;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px #000000;
}
@media (max-width: 1399px) {
  .category-card {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .category-card {
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  .category-card {
    border-top: 15px solid #FBBA00;
  }
}
@media (max-width: 767px) {
  .category-card {
    font-size: 12px;
  }
}
@media (max-width: 575px) {
  .category-card {
    --line:1.3rem;
    border-top: 10px solid #FBBA00;
    font-size: 9px;
  }
}
.category-card h3 {
  line-height: var(--line);
  text-decoration: none;
  margin-bottom: 0px;
}
.category-card h3 a {
  transition: 0.3s;
}
.category-card h3 a:hover, .category-card h3 a:focus {
  color: #FBBA00;
}
.category-card ul {
  height: calc(var(--line) * 6);
  overflow: hidden;
  margin: 0.75em 1.5em;
}
@media (max-width: 575px) {
  .category-card ul {
    margin: 0.5em;
  }
}
.category-card ul li a {
  display: block;
  color: #4A4A4A;
  font-size: 1.1em;
  transition: 0.3s;
  line-height: var(--line);
}
.category-card ul li a:hover, .category-card ul li a:focus {
  color: #FBBA00;
}
.category-card .more {
  display: block;
  text-decoration: none;
  background-color: #e5e5e5;
  color: #000;
  transition: 0.3s;
  font-size: 1.2em;
  font-weight: 500;
  width: 100%;
  text-align: center;
  padding: 0.5em;
}
.category-card .more:hover, .category-card .more:focus, .category-card .more:active {
  background-color: #FBBA00;
  color: #000;
}

@media (max-width: 1399px) {
  .product-card {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .product-card {
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  .product-card {
    font-size: 12px;
  }
}
@media (max-width: 575px) {
  .product-card {
    font-size: 10px;
  }
}
.product-card img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  z-index: 1;
}
@media (max-width: 1399px) {
  .product-card img {
    height: 220px;
  }
}
@media (max-width: 1199px) {
  .product-card img {
    height: 200px;
  }
}
@media (max-width: 575px) {
  .product-card img {
    height: 40vw;
  }
}
.product-card .top-left {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.product-card .btn-fav {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}
.product-card .discount {
  background-color: #FF5B5B;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.2em 0.75em;
  border-radius: 1em;
  font-size: 1.1em;
  font-weight: 700;
  color: #fff;
}
.product-card .bonus {
  background-color: #FBBA00;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.2em 0.75em;
  border-radius: 1em;
  font-size: 1.1em;
  font-weight: 700;
  color: #fff;
}
.product-card h5 {
  font-size: 1.2em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 2.4em;
}

.count-input {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 2px solid #C4C4C4;
  border-radius: 10px;
  padding: 0.65em 2em;
}
@media (max-width: 1199px) {
  .count-input {
    padding: 0.65em;
  }
}
@media (max-width: 575px) {
  .count-input {
    border-radius: 5px;
  }
}
.count-input button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: #676767;
  transition: 0.3s;
}
.count-input button:hover, .count-input button:focus {
  color: #FBBA00;
}
.count-input button:active {
  color: #FF5B5B;
}
.count-input input {
  border: none;
  flex: 1;
  text-align: center;
  padding: 0px;
  font-size: 1.3em;
  font-weight: 900;
  color: #000;
}

.btn-fav {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
}

.product-full {
  position: relative;
}
@media (max-width: 767px) {
  .product-full .count {
    background-color: #F2F2F2;
    position: fixed;
    bottom: 50px;
    left: 0px;
    width: 100%;
    z-index: 100;
    padding: 1em 3em;
    font-size: 1.3em;
  }
}
@media (max-width: 575px) {
  .product-full .count {
    padding: 0.5em 1em;
  }
}
.product-full .btn-fav {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
}
.product-full .rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.product-full .rating svg {
  color: #FBBA00;
  font-size: 1.5em;
  margin-right: 0.3em;
}
.product-full .rating span {
  color: #676767;
  font-size: 1.1em;
}
.product-full .images {
  --height: 90px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .product-full .images {
    --height: 75px;
  }
}
@media (max-width: 991px) {
  .product-full .images {
    margin-bottom: 3em;
  }
}
.product-full .images .thumbslides {
  padding: 0.5em;
  width: calc(var(--height) + 1em);
  height: calc((var(--height) + 20px) * 3);
}
@media (max-width: 575px) {
  .product-full .images .thumbslides {
    display: none;
  }
}
.product-full .images .thumbslides .swiper-slide {
  height: var(--height);
}
.product-full .images .thumbslides .swiper-slide img {
  width: 100%;
  height: var(--height);
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  transition: 0.3s;
}
.product-full .images .thumbslides .swiper-slide-thumb-active img {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.product-full .images .main {
  width: auto;
  flex: 1;
}
.product-full .images .main img {
  width: 100%;
  height: 500px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 1399px) {
  .product-full .images .main img {
    height: 450px;
  }
}
@media (max-width: 1199px) {
  .product-full .images .main img {
    height: 380px;
  }
}
@media (max-width: 575px) {
  .product-full .images .main img {
    height: 85vw;
  }
}
.product-full .criteria {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  font-weight: 500;
  font-size: 1.1em;
}
.product-full .criteria span {
  padding: 1em;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px #000000;
  border-radius: 4px;
}
.product-full .criteria span:not(:first-child) {
  margin-left: 1em;
}
.product-full .characteristics {
  background: #FFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 2em;
}
@media (max-width: 767px) {
  .product-full .characteristics {
    box-shadow: unset;
    border-radius: 0px;
    padding: 0px;
  }
}
.product-full .characteristics .specification {
  -moz-columns: 2;
       columns: 2;
  -moz-column-gap: 80px;
       column-gap: 80px;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 1em;
}
@media (max-width: 1199px) {
  .product-full .characteristics .specification {
    -moz-column-gap: 50px;
         column-gap: 50px;
  }
}
@media (max-width: 991px) {
  .product-full .characteristics .specification {
    -moz-columns: 1;
         columns: 1;
  }
}
@media (max-width: 575px) {
  .product-full .characteristics .specification {
    margin-bottom: 0px;
  }
}
.product-full .characteristics .specification .line {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px dotted #9C9C9C;
  margin-bottom: 1.5em;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}

.tabs {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100px auto;
}
@media (max-width: 767px) {
  .tabs {
    display: block;
  }
}
.tabs::before {
  content: "";
  position: absolute;
  top: 100px;
  left: 0px;
  width: 75%;
  z-index: 1;
  border-bottom: 1px solid #DADADA;
}
@media (max-width: 991px) {
  .tabs::before {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .tabs::before {
    content: unset;
  }
}
.tabs > button {
  position: relative;
  z-index: 2;
  grid-column: span 2;
  grid-row: 1/2;
  font-size: 1.5em;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
  margin-bottom: -1px;
}
@media (max-width: 991px) {
  .tabs > button {
    grid-column: span 3;
  }
}
@media (max-width: 767px) {
  .tabs > button {
    width: 100%;
    padding: 1em 0;
    text-align: left;
    border-top: 1px solid #DADADA;
  }
}
@media (max-width: 575px) {
  .tabs > button {
    padding: 0.75em 0;
  }
}
.tabs > button#tab-btn-2 {
  grid-column: span 3;
}
@media (max-width: 991px) {
  .tabs > button#tab-btn-2 {
    grid-column: span 4;
  }
}
.tabs > button.active {
  color: #FBBA00;
  border-bottom: 2px solid #FBBA00;
}
@media (max-width: 767px) {
  .tabs > button.active {
    border-bottom: 1px solid #FBBA00;
  }
}
.tabs > button:hover, .tabs > button:focus {
  color: #FBBA00;
}
.tabs .content {
  grid-column: 1/13;
  grid-row: 2/3;
  padding-top: 3em;
}
@media (max-width: 767px) {
  .tabs .content {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
@media (max-width: 575px) {
  .tabs .content {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.text p {
  font-size: 1.5em;
}
@media (max-width: 767px) {
  .text p {
    font-size: 1.2em;
  }
}

.review {
  border-bottom: 1px solid #DADADA;
  padding-bottom: 2em;
  margin-bottom: 2em;
}
@media (max-width: 991px) {
  .review {
    border-top: 1px solid #DADADA;
    border-bottom: unset;
    padding-top: 2em;
    padding-bottom: 0em;
  }
}
.review .text {
  color: #000;
  font-size: 1.5em;
}
@media (max-width: 1199px) {
  .review .text {
    font-size: 1.25em;
  }
}

.rating-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rating-info .val {
  color: #8E8E8E;
  font-size: 1.1em;
  width: 1.25em;
}
.rating-info .progress {
  flex: 1;
  height: 4px;
  background-color: #C6C6C6;
}
.rating-info .progress-bar {
  background-color: #FBBA00;
}
.rating-info .percent {
  color: #000;
  font-size: 1.1em;
  width: 2.5em;
  margin-left: 0.5em;
}

.offer {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Futura PT";
  position: relative;
}
.offer::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 0;
  transition: 0.3s;
  z-index: 0;
}
.offer:hover::after {
  opacity: 1;
}
.offer-1 {
  background-color: #FFABAB;
}
@media (max-width: 991px) {
  .offer-1 {
    align-items: flex-start;
  }
}
.offer-1 img {
  width: 400px;
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  z-index: 1;
}
@media (max-width: 1399px) {
  .offer-1 img {
    width: 350px;
  }
}
@media (max-width: 1199px) {
  .offer-1 img {
    width: 300px;
    height: 250px;
  }
}
@media (max-width: 991px) {
  .offer-1 img {
    width: 275px;
    height: 225px;
  }
}
@media (max-width: 767px) {
  .offer-1 img {
    width: 220px;
    height: 180px;
  }
}
@media (max-width: 575px) {
  .offer-1 img {
    width: 140px;
    height: 130px;
  }
}
.offer-2 {
  background-color: #FBBA00;
  padding: 1em 3em;
}
@media (max-width: 1199px) {
  .offer-2 {
    padding: 1em 2em;
  }
}
@media (max-width: 575px) {
  .offer-2 {
    padding: 1em;
  }
}
.offer-2 img {
  width: 300px;
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
  position: relative;
  z-index: 1;
}
@media (max-width: 1399px) {
  .offer-2 img {
    width: 250px;
    height: 250px;
  }
}
@media (max-width: 991px) {
  .offer-2 img {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 767px) {
  .offer-2 img {
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 575px) {
  .offer-2 img {
    width: 115px;
    height: 115px;
  }
}
.offer .text {
  flex: 1;
  padding: 2em;
  position: relative;
  z-index: 1;
}
@media (max-width: 1399px) {
  .offer .text {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .offer .text {
    padding: 1em;
    font-size: 12px;
  }
}
@media (max-width: 991px) {
  .offer .text {
    font-size: 10px;
    padding: 2em;
  }
}
@media (max-width: 767px) {
  .offer .text {
    font-size: 8px;
    padding: 1em 2em;
  }
}
@media (max-width: 575px) {
  .offer .text {
    padding: 1em;
    font-size: 6px;
  }
  .offer .text .fs-35 {
    font-size: 2.5em;
  }
}
.offer .expiration {
  width: 240px;
  text-align: center;
  font-weight: 500;
  font-size: 1.5em;
  position: relative;
  z-index: 1;
}
@media (max-width: 1399px) {
  .offer .expiration {
    font-size: 1.4em;
    width: 200px;
  }
}
@media (max-width: 1199px) {
  .offer .expiration {
    width: 180px;
  }
}
@media (max-width: 991px) {
  .offer .expiration {
    width: 140px;
    position: absolute;
    bottom: 1em;
    left: 1em;
    font-size: 1.2em;
  }
}
@media (max-width: 767px) {
  .offer .expiration {
    width: 120px;
    bottom: 0.5em;
    left: 0em;
    font-size: 1em;
    font-weight: 400;
  }
}
@media (max-width: 575px) {
  .offer .expiration {
    left: 0.75em;
    font-size: 0.8em;
    text-align: left;
  }
}

article.preview figure {
  background: #F2F2F2;
  box-shadow: 0px 0px 2px #000000;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
article.preview figure img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  z-index: 1;
  filter: brightness(1);
  transition: 0.3s;
}
@media (max-width: 1399px) {
  article.preview figure img {
    height: 260px;
  }
}
@media (max-width: 1199px) {
  article.preview figure img {
    height: 235px;
  }
}
@media (max-width: 767px) {
  article.preview figure img {
    height: 200px;
  }
}
@media (max-width: 575px) {
  article.preview figure img {
    height: 60vw;
  }
}
article.preview figure:hover {
  cursor: pointer;
}
article.preview figure:hover img {
  filter: brightness(0.7);
}
article.preview figure figcaption {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 250px;
  height: 100%;
  z-index: 2;
  background: radial-gradient(118.67% 118.67% at -4.13% -7.17%, rgba(246, 246, 246, 0.8) 0%, rgba(237, 237, 237, 0.496) 100%);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 1em;
}
@media (max-width: 1399px) {
  article.preview figure figcaption {
    width: 220px;
  }
}
@media (max-width: 1199px) {
  article.preview figure figcaption {
    width: 200px;
  }
}
@media (max-width: 767px) {
  article.preview figure figcaption {
    width: 165px;
  }
}
@media (max-width: 575px) {
  article.preview figure figcaption {
    width: 55vw;
  }
}
article.preview figure figcaption h3 {
  text-decoration: unset;
}
@media (max-width: 1399px) {
  article.preview figure figcaption h3 {
    font-size: 1.35em;
  }
}
@media (max-width: 767px) {
  article.preview figure figcaption h3 {
    font-size: 1.2em;
  }
}
article.preview h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
article.full figure {
  background: #F2F2F2;
  box-shadow: 0px 0px 2px #000000;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 3em;
}
article.full figure img {
  width: 100%;
  height: 650px;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  z-index: 1;
  filter: brightness(1);
  transition: 0.3s;
}
@media (max-width: 1399px) {
  article.full figure img {
    height: 600px;
  }
}
@media (max-width: 1199px) {
  article.full figure img {
    height: 500px;
  }
}
@media (max-width: 991px) {
  article.full figure img {
    height: 400px;
  }
}
@media (max-width: 767px) {
  article.full figure img {
    height: 335px;
  }
}
@media (max-width: 575px) {
  article.full figure img {
    height: 60vw;
  }
}
article.full figure:hover {
  cursor: pointer;
}
article.full figure:hover img {
  filter: brightness(0.7);
}
article.full figure figcaption {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 45%;
  height: 100%;
  z-index: 2;
  background: radial-gradient(118.67% 118.67% at -4.13% -7.17%, rgba(246, 246, 246, 0.8) 0%, rgba(237, 237, 237, 0.496) 100%);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 2em;
}
@media (max-width: 767px) {
  article.full figure figcaption {
    width: 55%;
  }
}
@media (max-width: 575px) {
  article.full figure figcaption {
    width: 50vw;
    padding: 1em;
  }
}
article.full figure figcaption h2 {
  font-size: 3em;
}
@media (max-width: 1199px) {
  article.full figure figcaption h2 {
    font-size: 2.5em;
  }
}
@media (max-width: 991px) {
  article.full figure figcaption h2 {
    font-size: 2em;
  }
}
@media (max-width: 575px) {
  article.full figure figcaption h2 {
    font-size: 1.5em;
  }
}
article.full h3 {
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 1.25em;
  margin-top: 2em;
}
@media (max-width: 575px) {
  article.full h3 {
    margin-bottom: 0.75em;
    margin-top: 1.5em;
  }
}
article.full p {
  font-size: 1.5em;
  margin-bottom: 1.5em;
}
@media (max-width: 991px) {
  article.full p {
    font-size: 1.3em;
    margin-bottom: 1.3em;
  }
}
@media (max-width: 575px) {
  article.full p {
    margin-bottom: 1em;
  }
}

#delivery-map {
  position: relative;
}
#delivery-map .btns {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-bottom: 2em;
}
@media (min-width: 992px) {
  #delivery-map .btns {
    display: none;
  }
}
#delivery-map .btns button {
  color: #959595;
  font-size: 1.4em;
  padding: 1em;
  font-weight: 700;
  border-bottom: 2px solid #DADADA;
  flex-grow: 1;
}
#delivery-map .btns button.active {
  color: #000;
  border-bottom: 2px solid #FBBA00;
}
@media (max-width: 575px) {
  #delivery-map .btns button {
    padding: 0.75em 0;
  }
}
#delivery-map .params {
  padding-top: 3em;
  padding-bottom: 6em;
}
@media (max-width: 991px) {
  #delivery-map .params {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
#delivery-map img {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 991px) {
  #delivery-map img {
    position: relative;
    height: 500px;
  }
}
@media (max-width: 767px) {
  #delivery-map img {
    height: 450px;
  }
}
@media (max-width: 575px) {
  #delivery-map img {
    height: 350px;
  }
}
#delivery-map form {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px #000000;
  position: relative;
  z-index: 10;
  padding: 3em;
}
@media (max-width: 991px) {
  #delivery-map form {
    padding: 0px;
    box-shadow: unset;
  }
}
#delivery-map form .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2em;
  transition: 0.3s;
}
@media (max-width: 1199px) {
  #delivery-map form .box {
    padding: 1em;
  }
}
#delivery-map form .box .btn-1 {
  border-radius: 4px;
}
#delivery-map form input:checked + .box {
  box-shadow: 0px 0px 2px #FBBA00;
}

/* Filter (desktop) */
.filter {
  position: -webkit-sticky;
  position: sticky;
  top: 140px;
  left: 0px;
  width: 100%;
}
.filter fieldset {
  margin-bottom: 2em;
}
.filter fieldset legend {
  font-size: 1.1em;
  font-weight: 700;
  margin-bottom: 0.75em;
}
.filter fieldset label {
  margin-bottom: 0.75em;
}

/* Filter (mobile) */
.filterMobile {
  --top: 60px;
  --bottom: 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 575px) {
  .filterMobile {
    --top: 50px;
    --bottom: 50px;
  }
}
.filterMobile .main {
  height: calc(100vh - var(--bottom));
}
.filterMobile .main fieldset {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.filterMobile .main .top {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #DADADA;
  padding: 0 1em;
  height: var(--top);
}
.filterMobile .main .top legend {
  flex: 1;
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0.5em;
}
.filterMobile .main .top button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}
.filterMobile .main ul {
  height: calc(100vh - var(--top) - var(--bottom));
  list-style: none;
  padding-left: 0px;
  overflow-y: auto;
  font-size: 1.2em;
}
.filterMobile .main ul li {
  padding: 1em;
  border-bottom: 1px solid #DADADA;
}
.filterMobile .main ul li button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.filterMobile .bottom {
  background-color: #F2F2F2;
  padding: 0 1em;
  height: var(--bottom);
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
}

#contacts-page .box {
  min-height: 400px;
  padding: 2em;
}
@media (max-width: 1199px) {
  #contacts-page .box {
    min-height: 350px;
  }
}
@media (max-width: 767px) {
  #contacts-page .box {
    box-shadow: none;
    padding: 0px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    border-bottom: 1px solid #DADADA;
    padding: 2em 0px;
  }
}
#contacts-page .shop {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  justify-content: center;
  padding: 0px;
}
@media (max-width: 767px) {
  #contacts-page .shop {
    display: block;
    border-bottom: none;
  }
}
#contacts-page .shop .map {
  width: 45%;
}
@media (max-width: 767px) {
  #contacts-page .shop .map {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}
#contacts-page .shop .map img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#contacts-page .shop-info {
  flex: 1;
  padding: 2em;
}
@media (max-width: 767px) {
  #contacts-page .shop-info {
    padding: 2em 0px;
  }
}

/* SHOPPING CART */
.cart-item {
  padding: 2em 0;
  border-bottom: 1px solid #DADADA;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .cart-item {
    align-items: stretch;
  }
}
.cart-item .sale-price {
  font-size: 17px;
  font-weight: normal;
}
.cart-item img {
  width: 160px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: #F2F2F2;
  border-radius: 8px;
  padding: 0.75em;
}
@media (max-width: 1399px) {
  .cart-item img {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 1199px) {
  .cart-item img {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 575px) {
  .cart-item img {
    width: 90px;
    height: 90px;
  }
}
.cart-item .info {
  margin: 0 2em;
  flex: 1;
  display: flex;
  align-items: center;
}
@media (max-width: 1399px) {
  .cart-item .info {
    margin: 0 1em;
  }
}
@media (max-width: 767px) {
  .cart-item .info {
    flex-direction: column;
    align-items: flex-start;
  }
}
.cart-item .info .count-input {
  padding: 0.65em;
  width: 150px;
}
@media (max-width: 1199px) {
  .cart-item .info .count-input {
    width: 120px;
  }
}
.cart-item .info .price {
  font-size: 1.5em;
  font-weight: 700;
  width: 120px;
  text-align: right;
}
@media (max-width: 1399px) {
  .cart-item .info .price {
    width: 100px;
  }
}
@media (max-width: 767px) {
  .cart-item .info .price {
    width: 100%;
    text-align: left;
    margin: 0.5em 0;
  }
}
.cart-item .btns {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .cart-item .btns {
    justify-content: space-between;
  }
}

.total {
  background: #F2F2F2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
@media (max-width: 991px) {
  .total {
    position: fixed;
    z-index: 100;
    bottom: 50px;
    left: 0px;
    width: 100%;
    border-radius: 0px;
    box-shadow: unset;
    border-top: 1px solid #DADADA;
    padding: 1.5em 20%;
  }
  .total-padding {
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .total-padding {
    padding-bottom: 100px;
  }
}
@media (max-width: 575px) {
  .total {
    padding: 1em;
  }
  .total-padding {
    padding-bottom: 80px;
  }
}
.total hr {
  height: 2px !important;
  background-color: #DADADA;
  margin: 1em 0 0.5em;
  opacity: 1;
}
@media (max-width: 991px) {
  .total hr {
    margin: 0.5em 0;
  }
}
.total table {
  width: 100%;
  font-size: 1.1em;
}
.total table tr td {
  padding: 0.5em 0;
}
.total table tr td:last-child {
  text-align: right;
}

aside {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  width: 100%;
  height: 230px;
}

#checkout hr {
  height: 1px !important;
  background-color: #DADADA;
  margin: 1em 0;
  opacity: 1;
}

#enter-page {
  padding: 6em 0;
}
@media (max-width: 1199px) {
  #enter-page {
    padding: 5em 0;
  }
}
@media (max-width: 991px) {
  #enter-page {
    padding: 4em 0;
  }
}
@media (max-width: 767px) {
  #enter-page {
    padding: 3em 0;
  }
}
@media (max-width: 575px) {
  #enter-page {
    padding: 2em 0;
  }
}

/* Account */
.account-nav {
  padding: 1.25em 0px;
}
@media (max-width: 991px) {
  .account-nav {
    padding: 0px;
  }
}
.account-nav ul {
  list-style: none;
  padding-left: 0px;
}
.account-nav ul li a,
.account-nav ul li button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75em 1.5em;
  position: relative;
  overflow: hidden;
  font-size: 1.35em;
  font-weight: 500;
  text-align: left;
}
@media (max-width: 991px) {
  .account-nav ul li a,
.account-nav ul li button {
    font-size: 1.5em;
    font-weight: 700;
  }
}
@media (max-width: 575px) {
  .account-nav ul li a,
.account-nav ul li button {
    padding: 1em 0px;
  }
}
.account-nav ul li a.active,
.account-nav ul li button.active {
  color: #FBBA00;
}
.account-nav ul li a.active::before,
.account-nav ul li button.active::before {
  content: "";
  position: absolute;
  top: 10%;
  left: -2.5px;
  height: 80%;
  width: 5px;
  background-color: #FBBA00;
  border-radius: 2.5px;
}
@media (min-width: 992px) {
  .account-nav ul li a svg,
.account-nav ul li button svg {
    display: none;
  }
}
.account-nav ul li button::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 1.5em;
  right: 1.5em;
  height: 1px;
  background-color: #DADADA;
}
@media (max-width: 575px) {
  .account-nav ul li button::after {
    left: 0px;
    right: 0px;
  }
}
@media (max-width: 991px) {
  .account .box-2 {
    box-shadow: none;
    border-radius: unset;
  }
}

.order {
  margin-bottom: 3em;
}
.order hr {
  background-color: #DADADA;
  height: 1px;
  width: 100%;
  margin: 1em 0px;
  opacity: 1;
}
.order .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3em;
}
@media (max-width: 767px) {
  .order .info {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 991px) {
  .order-page {
    padding-bottom: 6em;
  }
}
.order-item {
  border-top: 1px solid #DADADA;
  padding: 2em 0;
  display: flex;
}
.order-item img {
  width: 200px;
  height: 200px;
  -o-object-fit: contain;
     object-fit: contain;
  background: #F2F2F2;
  border-radius: 10px;
  padding: 1em;
  margin-right: 2em;
}
@media (max-width: 1399px) {
  .order-item img {
    width: 150px;
    height: 150px;
    margin-right: 1em;
  }
}
@media (max-width: 1199px) {
  .order-item img {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 575px) {
  .order-item img {
    width: 90px;
    height: 90px;
  }
}
.order-item .info {
  flex: 1;
  font-size: 1.1em;
}
@media (max-width: 1399px) {
  .order-item .info {
    font-size: 1em;
  }
}
@media (max-width: 767px) {
  .order-item .info {
    font-size: 1.2em;
  }
}

.address {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}
@media (max-width: 767px) {
  .address button.btn, .address button.btn-1, .address button.btn-2, .address button.btn-3, .address button.btn-4 {
    color: #fff;
    padding: 0.4em;
  }
}

.bonus-page .box-3 {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 2em;
}
@media (max-width: 767px) {
  .bonus-page .box-3 {
    padding: 1.5em;
  }
}
.bonus-card {
  background: #FBBA00;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 2em;
  height: 250px;
  position: relative;
}
@media (max-width: 1399px) {
  .bonus-card {
    height: 225px;
  }
}
@media (max-width: 1199px) {
  .bonus-card {
    height: 200px;
  }
}
@media (max-width: 767px) {
  .bonus-card {
    height: 180px;
  }
}
.bonus-card > * {
  z-index: 1;
}
.bonus-card .logo {
  position: absolute;
  bottom: 1.5em;
  right: 2em;
  height: 55px;
  width: 150px;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 10;
}
@media (max-width: 1399px) {
  .bonus-card .logo {
    bottom: 1em;
    height: 40px;
    width: 100px;
  }
}
.bonus-operation {
  padding-bottom: 1.5em;
  border-bottom: 2px solid #DADADA;
  margin-bottom: 1.5em;
}
@media (max-width: 767px) {
  .bonus-operation {
    border-bottom: 1px solid #DADADA;
  }
}
.bonus-tabs {
  display: flex;
  align-items: center;
}
.bonus-tabs button {
  font-size: 1.3em;
  color: #959595;
  font-weight: 500;
  border-right: 2px solid #DADADA;
  padding-right: 2em;
}
.bonus-tabs button:not(:first-child) {
  margin-left: 2em;
}
@media (max-width: 575px) {
  .bonus-tabs button:not(:first-child) {
    margin-left: 1em;
  }
}
.bonus-tabs button.active {
  color: #FBBA00;
}
@media (max-width: 575px) {
  .bonus-tabs button {
    padding-right: 1em;
  }
}

footer {
  height: 500px;
  background-color: #0F0F0F;
  color: #fff;
  padding: 3.5em 0;
}
@media (max-width: 1199px) {
  footer {
    height: 450px;
  }
}
@media (max-width: 991px) {
  footer {
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    padding: 0;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
  }
}
footer ul {
  font-size: 1.1em;
}
footer ul li:not(:last-child) {
  margin-bottom: 1em;
}
footer ul li a {
  text-decoration: underline;
  text-decoration-thickness: from-font;
  transition: 0.3s;
}
footer ul li a:hover, footer ul li a:focus, footer ul li a:active {
  color: #FBBA00;
  text-decoration: underline;
  text-decoration-thickness: from-font;
}
footer hr {
  background-color: #fff;
  height: 1px !important;
  border: none;
  opacity: 1;
}
footer .social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
footer .social a:not(:first-child) {
  margin-left: 1.25em;
}
footer .social img {
  width: 3em;
  height: 3em;
  -o-object-fit: contain;
     object-fit: contain;
}
footer .mobile {
  display: none;
  height: 100%;
}
@media (max-width: 991px) {
  footer .mobile {
    display: block;
  }
}
footer .mobile nav {
  height: 100%;
}
footer .mobile nav ul {
  height: 100%;
  list-style: none;
  padding-left: 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
footer .mobile nav ul li {
  margin-bottom: 0px;
}
footer .mobile nav ul li a .stroke path {
  stroke: #000000;
}
footer .mobile nav ul li a .fill path {
  fill: #000000;
}
footer .mobile nav ul li a.active .stroke path {
  stroke: #FBBA00;
}
footer .mobile nav ul li a.active .fill path,
footer .mobile nav ul li a.active .fill-active path {
  fill: #FBBA00;
}

.offcanvas-start {
  width: 500px;
}
.offcanvas-body {
  padding: 0px;
  position: relative;
}/*# sourceMappingURL=style.css.map */